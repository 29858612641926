.mm-article {
  .display-1 {
    font-size: 50px;
  }
}

.mm-height {
  height: 100%;
}

.mm-text-primary {
  color: $brand-primary !important;
}

.mm-text-secondary {
  color: $orange-200 !important;
}

.mm-text-secondary1 {
  color: $brand-secondary !important;
}

.text-black {
  color: $black !important;
}

.mm-text-gray {
  color: $brand-gray-600 !important;
}

.ml-auto {
  margin-left: auto !important;
}

.font-sf-ui-display {
  font-family: $font-sf-ui-display;
}

.font-josefin-sans {
  font-family: $font-josefin-sans;
}

.font-acumin-pro {
  font-family: $font-acumin-pro;
}

.font-poppins {
  font-family: $font-poppins;
}

.font-mulish {
  font-family: $font-mulish;
}

.mm-gradient {
  // background: rgb(255, 255, 255);
  // background: -webkit-gradient(
  //   linear,
  //   left top,
  //   left bottom,
  //   from(rgba(255, 255, 255, 1)),
  //   color-stop(50%, rgba(241, 244, 244, 1))
  // );
  // background: -o-linear-gradient(
  //   top,
  //   rgba(255, 255, 255, 1) 0%,
  //   rgba(241, 244, 244, 1) 50%
  // );
  // background: linear-gradient(
  //   180deg,
  //   rgba(255, 255, 255, 1) 0%,
  //   rgba(241, 244, 244, 1) 50%
  // );
  background: rgb(255, 255, 255);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(255, 255, 255, 1)),
    to(rgba(198, 219, 219, 1))
  ) !important;
  background: -o-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(198, 219, 219, 1) 100%
  ) !important;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(198, 219, 219, 1) 100%
  ) !important;
}

.rm-flex-row-between {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.mm-icon-bg {
  background-color: $white;
  border-radius: 5px;
  vertical-align: middle;
  display: inline-block;
  text-align: center;
}

.wh-40-30 {
  width: 40px;
  height: 30px;
  line-height: 29px;
}

.wh-29 {
  width: 29px;
  height: 29px;
  line-height: 29px;
}

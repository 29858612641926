@mixin btn-style($border-radius) {
  display: inline-block;
  font-weight: $ft_Weight_SM;
  text-align: center;
  // white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 14.5px 20px;
  font-size: 18px;
  line-height: 24px;
  border-radius: $border-radius;
  cursor: pointer;
  // effect btn
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  // letter-spacing: 1.5px;
  @include transition(all 0.3s ease-in-out);

  // &:after {
  //   content: "";
  //   display: block;
  //   position: absolute;
  //   width: 100%;
  //   height: 100%;
  //   top: 0;
  //   left: 0;
  //   pointer-events: none;
  //   background: radial-gradient(circle, $white 10%, transparent 10.01%) no-repeat 50%;
  //   transform: scale(10, 10);
  //   opacity: 0;
  //   transition: transform 0.5s, opacity 1s;
  // }

  // &:active:after {
  //   transform: scale(0, 0);
  //   opacity: 0.3;
  //   transition: 0s;
  // }

  &:focus,
  &:active {
    box-shadow: none;
    outline: 0;
  }
}

@mixin btn-color-style($color, $background-color, $border-color) {
  color: $color;
  background-color: $background-color;
  border-color: $border-color;
}

.theme_btn {
  @include btn-style(30px);
}

.theme_btn_rdus {
  @include btn-style(25px);
}

.theme_primary {
  @include btn-color-style($orange-200, $brand-primary, $brand-primary);
}

.theme_primary:hover,
.theme_primary:focus,
.theme_primary:active {
  @include btn-color-style(
    $orange-200,
    $brand-primary-hover,
    $brand-primary-hover
  );
}

.theme_secondary {
  @include btn-color-style($brand-primary, $brand-secondary, $brand-secondary);
}

.theme_secondary:hover {
  @include btn-color-style(
    $brand-primary,
    $brand-secondary-hover,
    $brand-secondary-hover
  );
}

.theme_success {
  @include btn-color-style($white, #28a745, #28a745);
}

.theme_success:hover {
  @include btn-color-style($white, #218838, #1e7e34);
}

.theme_danger {
  @include btn-color-style($white, $brand-danger, $brand-danger);
}

.theme_danger:hover {
  @include btn-color-style($white, $brand-danger-hover, $brand-danger-hover);
}

.theme_warning {
  @include btn-color-style($brand-primary, $brand-warning, $brand-warning);
}

.theme_warning:hover {
  @include btn-color-style(
    $brand-primary,
    $brand-warning-hover,
    $brand-warning-hover
  );
}

.theme_info {
  @include btn-color-style($white, #17a2b8, #17a2b8);
}

.theme_info:hover {
  @include btn-color-style($white, #138496, #117a8b);
}

.theme_light {
  @include btn-color-style(#212529, #f8f9fa, #f8f9fa);
}

.theme_light:hover {
  @include btn-color-style(#212529, #e2e6ea, #dae0e5);
}

.theme_dark {
  @include btn-color-style($white, #343a40, #343a40);
}

.theme_dark:hover {
  @include btn-color-style(#212529, #e2e6ea, #dae0e5);
}

.theme_link {
  font-weight: 400;
  color: $brand-primary;
  background-color: transparent;
}

.theme_link:hover {
  color: $brand-primary-hover;
  background-color: transparent;
  border-color: transparent;
}

/*outline btn css*/

.theme_outline_primary {
  @include btn-color-style($brand-primary, transparent, $brand-primary);
  background-image: none;
}

.theme_outline_primary:hover {
  @include btn-color-style($white, $brand-primary, $brand-primary);
}

.theme_outline_success {
  @include btn-color-style(#28a745, transparent, #28a745);
  background-image: none;
}

.theme_outline_success:hover {
  @include btn-color-style($white, #28a745, #28a745);
}

.theme_outline_danger {
  @include btn-color-style(#dc3545, transparent, #dc3545);
  background-image: none;
}

.theme_outline_danger:hover {
  @include btn-color-style($white, #dc3545, #dc3545);
}

.theme_outline_warning {
  color: #ffc107;
  background: transparent none;
  border-color: #ffc107;
}

.theme_outline_warning:hover {
  @include btn-color-style(#212529, #ffc107, #ffc107);
}

.theme_outline_info {
  @include btn-color-style(#17a2b8, transparent, #17a2b8);
  background-image: none;
}

.theme_outline_info:hover {
  @include btn-color-style($white, #17a2b8, #17a2b8);
}

.theme_outline_light {
  color: #f8f9fa;
  background: transparent none;
  border-color: #f8f9fa;
}

.theme_outline_light:hover {
  @include btn-color-style(#212529, #f8f9fa, #f8f9fa);
}

.theme_outline_dark {
  @include btn-color-style($black, transparent, $black);
}

.theme_outline_dark:hover {
  @include btn-color-style($white, #343a40, #343a40);
}

/* // outline btn css*/
.theme_btn_lg {
  font-weight: 500;
  font-size: 18px;
  min-width: 272px;
}

.theme_btn_md {
  font-size: 16px;
  padding: 12px 10px;
}

.theme_btn_sm {
  font-size: 13px;
  padding: 9.5px 15px 9.5px 15px;
  line-height: 13px;
  vertical-align: middle;
}

%primary_shad {
  box-shadow: -3px 0 22px 0 rgba(29, 73, 149, 0.3);
  -webkit-box-shadow: -3px 0 22px 0 rgba(29, 73, 149, 0.3);
  -moz-box-shadow: -3px 0 22px 0 rgba(29, 73, 149, 0.3);
}

.btn_primary_shad {
  @extend %primary_shad;
}

// html,
// body {
//   position: relative;
//   width: 100%;
//   height: 100%;
//   overflow-x: hidden;
//   overflow-y: auto;
// }

html {
  height: 100%;
  overflow: hidden;
  position: relative;
  font-size: 16px;

  @include respond(phone) {
    font-size: 15px;
  }
}

body {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

a {
  text-decoration: none;
  color: $brand-primary;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;

  &:hover {
    text-decoration: none;
    color: $brand-secondary;
  }
}

img {
  max-width: 100%;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

/**** Site section  ****/

.site-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.main-cont-wrapper {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  padding: 0;
  z-index: 1;
  padding-top: 51px;
  position: relative;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;

  @include respond(tab-port-min) {
    padding-top: 76px;
  }

  // height: calc(100% - 170px);
  .container {
    padding-left: 25px;
    padding-right: 25px;
  }

  &.mm-without-header {
    padding-top: 0;

    .site-section {
      padding-top: 40px;
    }
  }
}

.site-section {
  padding: 25px 0 35px 0;

  @include respond(tab-port-min) {
    padding: 35px 0;
  }
}

/**** // Site section ****/

.mm-bttom-wrapper {
  padding: 15px 0 0;
}

.mm-article {
  h1 {
    font-size: 24px;
    line-height: 29px;
  }

  .display-4 {
    font-size: 30px;
    line-height: 36px;
  }

  h2 {
    font-size: 22px;
    line-height: 22px;
  }

  h3 {
    font-size: 20px;
    line-height: 24px;
  }

  h6 {
    font-size: 16px;
    line-height: 24px;
    color: $brand-primary-100;
  }

  .mm-h2 {
    font-size: 22px;
    line-height: 22px;
    color: $brand-primary-100;
  }
}

.back-link-wrapper {
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
  vertical-align: middle;
  color: $brand-primary;
  margin-bottom: 10px;
  display: block;

  img {
    width: 11px;
    vertical-align: -3.5px;
    margin-right: 3px;
  }
}

.card-plan {
  .mm-btn-wrapper {
    padding-bottom: 0;
  }
}

.mm-header-menu-wrapper-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: none;
  overflow: hidden;
  z-index: 998;
  width: 100%;
  height: 100%;
  background: rgba(34, 34, 34, 0.4);
  -webkit-animation: all 0.6s ease-in-out;
  animation: all 0.6s ease-in-out;
}

.body-active {
  .mm-header-menu-wrapper-overlay {
    display: block;
  }

  .main_header {
    background-color: transparent;
    background: transparent;
  }
}

.mm-dots-shape .card-body {
  background-image: image_url("dots-repeat.svg");
  background-size: 114px;
  background-repeat: no-repeat;
  background-position: 15px 11px;
}

/* mm-profile-details-wrapper*/
.edit-profile-section {
  .mm-profile-cont-wrapper {
    h4 {
      font-size: 16px;
      line-height: 24px;
      color: #455a64 !important;
      font-weight: 500;
    }
  }
  .mm-profile-details-wrapper {
    text-align: center;
    .mm-notification-wrapper {
      margin-bottom: 0;
    }
    padding: 0;
  }
  .main-cont-login-wrapper {
    .site-section {
      padding-top: 25px;
      background-color: #c6dbdb;
    }
  }
  .mm-profile {
    position: relative;
    width: 109px !important;
    height: 109px !important;
  }
  .mm-profile-camera {
    position: absolute;
    bottom: 0;
    right: 31%;
    height: 38px !important;
    width: 38px !important;
  }
  .mm-edit {
    width: 12px;
    margin-left: -25px;
  }
  .input-name-wrap {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .mm-profile-wrapper {
    position: relative;
    max-width: 290px;
    margin: 0 auto;
  }
  .disabled {
    color: #47494db3 !important;
  }
  .label_modify {
    font-size: 12px;
    line-height: 22px;
    color: #174e4d;
    font-weight: 600;
    margin: 0;
  }
  .mt-30 {
    margin-top: 30px;
  }
  .minmind-form-wrapper {
    padding-top: 0;
  }
  .input_modify {
    border-bottom: 1px solid #97c1c1 !important;
  }
  .pd-45 {
    padding-right: 45px !important;
  }
}
/*Change password css start here */
.change-password-section {
  .input_modify {
    font-weight: 600;
    font-size: 16px;
  }
  .mm-edit {
    width: 18px;
  }
  .notify {
    font-size: 14px;
    line-height: 24px;
    color: #094752;
    font-weight: 600;
  }
  .mb-5 {
    margin-bottom: 5px !important;
  }
}
/*Change password css start here */

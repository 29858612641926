//-- 2.3 lesson1-2 scss start here---//
.minmind-cards {
  padding: 25px;
  // background-image: linear-gradient(#174e4d, #60a492);
  border-radius: 0;

  .minmind-cards-title {
    color: $brand-primary;
  }

  .minmind-card-body {
    p {
      font-weight: 400;
      color: $brand-primary;

    }
  }
}

.minmind-cards-title {
  text-align: center;
  font-size: 24px;
  line-height: 34px;
  color: #fff;
  margin-bottom: 0;
}

.minmind-card-body {
  text-align: center;

  p {
    font-size: 14px;
    line-height: 22px;
    color: #fff;
    font-weight: 300;
    margin: 26px 0 0;
  }
}

.notify-txt {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #f2795e;
  margin: 32px 0;
  text-align: center;
}

//-- 2.3 lesson1-2 scss end here----//

//-- 2.4 lesson2-2 scss start here---//
.mm-subtitle {
  text-align: center;
  font-weight: 500;
  line-height: 24px;
  color: #455a64;
  margin-top: 12px;
}

.bg-linear-light {
  background: linear-gradient(180deg, white 0%, #f1f4f4 50%);
}

.minmind-card-body {
  .grn-txt {
    color: #174e4d;
  }

  .blk-text {
    color: #455A64;
  }
}

.play-button {
  display: block;
  text-align: center;
  margin-bottom: 30px;
}

.mm-figure-audio {
  border-bottom: 1px solid #E4E4E4;
  padding-bottom: 10px;
  margin-bottom: 0;
}

/* All reactions are normal css start here!*/

.img-text-section {
  .mm-card-fig {
    margin-bottom: 22px;
  }

  .minmind-card-body {
    margin-bottom: 20px;

    p {
      margin: 0;
    }
  }

}

/*How to repair after making a microaggression.*/



//-- 2.4 lesson2-2 scss end here----//
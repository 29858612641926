.main-cont-login-wrapper {
  padding-top: 0 !important;
  background: rgb(255, 255, 255);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(255, 255, 255, 1)),
    to(rgba(244, 244, 244, 1))
  );
  background: -o-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(244, 244, 244, 1) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(244, 244, 244, 1) 100%
  );

  .mm-form {
    .input_modify {
      background-color: transparent;
    }

    .theme_btn {
      padding: 14.5px 25px;
    }

    .mm-btn-wrapper {
      padding: 0 12px;
    }

    .mm-password {
      margin-top: 20px;

      a {
        color: $brand-primary;
        font-size: 13px;
        line-height: 24px;
        font-weight: $ft_Weight_M;
      }
    }

    .mm-link {
      color: $orange-100;

      &:hover,
      &:focus,
      &:active {
        color: $orange-100-hover;
      }
    }
  }

  .site-section {
    padding-top: 25px;
  }

  .mm-article {
    margin-bottom: 25px;

    h1 {
      margin-bottom: 10px;
      font-size: 30px;
      line-height: 36px;
      font-weight: $ft_Weight_SM;
      font-family: $font-sf-ui-display;
    }

    h2 {
      color: $brand-primary-100;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
    }

    h6 {
      color: $brand-primary-100;
    }
  }

  .mm-form-group {
    margin-bottom: 35px;
  }

  &.main-cont-login-v-mm-grindient {
    background: rgb(255, 255, 255);
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(rgba(255, 255, 255, 1)),
      to(rgba(198, 219, 219, 1))
    ) !important;
    background: -o-linear-gradient(
      top,
      rgba(255, 255, 255, 1) 0%,
      rgba(198, 219, 219, 1) 100%
    ) !important;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(198, 219, 219, 1) 100%
    ) !important;

    .minmind-tabs-wrapper {
      .mm-nav-tabs {
        border-color: $primary-border;
        background-color: $white;
      }

      .mm-form {
        .input_modify {
          border-bottom-color: $input-border;
        }
      }
    }
  }

  .minmind-tabs-wrapper {
    margin-left: 0;
    margin-right: 0;
  }

  .mm-form-group {
    margin-bottom: 15px;
  }

  .mm-bttom-wrapper {
    padding: 15px 0 10px;
  }
}

.minmind-form-wrapper {
  padding-top: 20px;

  .mm-form {
    .label_modify {
      color: $brand-primary;
    }

    .input_modify {
      padding-left: 10px;
      padding-right: 10px;
      border-top: 0;
      border-radius: 0;
      border-left: 0;
      border-right: 0;
      border-bottom-color: $input-border;
      height: 40px;

      &:focus,
      &:active {
        border-bottom-color: $brand-primary;
      }
    }
  }
}

.signup-link-wrap {
  margin-top: 200px;

  a {
    color: #000;
    font-size: 18px;
    font-weight: 600;
  }

  .signup-link {
    color: #e1d75d;
  }
}

/** start tabs css here **/
.minmind-tabs-wrapper {
  .mm-nav-tabs {
    border-radius: 25px;
    border: 1px solid $input-border;
    padding: 5px;

    .nav-item {
      width: 50%;
    }

    .nav-link {
      border-radius: 30px;
      width: 100%;
      color: $brand-primary;
      border: 0;
      padding: 14px 20px;
      font-size: 15px;
      line-height: 16px;
      font-weight: $ft_Weight_SM;

      &.active {
        background-color: $brand-primary;
        color: $orange-100;
      }
    }
  }
}

/** end tabs css here **/

.innerShadow {
  background:
    /* Shadow Cover TOP */ linear-gradient(#f9fbfb 1%, #f9fbfb00)
      center top,
    /* Shadow Cover BOTTOM */
      linear-gradient(rgb(206, 224, 224), rgb(206, 224, 224) 70%) center bottom,
    /* Shadow TOP */
      radial-gradient(
        farthest-side at 50% 0,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      center top,
    /* Shadow BOTTOM */
      radial-gradient(
        farthest-side at 50% 100%,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      center bottom;

  background-repeat: no-repeat;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  background-attachment: local, local, scroll, scroll;

  border-radius: 3%;
}

.site-section {
  .card-plan {
    min-width: 100%;
    margin-bottom: 20px;
    margin-top: 0;
    border-radius: 5px;
    border-color: $input-border;

    .card-body {
      padding-left: 15px;
      padding-right: 15px;
    }

    .mm-lock-wrap {
      background-color: $brand-primary-500;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;

      img {
        width: 30px;
        height: 30px;
      }

    }

    p {
      margin-top: 10px;
      font-size: 13px;
      line-height: 19px;
      color: $brand-primary-300;
    }

    .card-body {
      .mm-border {
        border-bottom: 1px solid $input-border;
        padding-bottom: 10px;
      }
    }

    .mm-drk-grn {
      color: #009186;
      font-size: 14px;
      line-height: 18px;
    }

    .mm-price-text {
      p {
        font-size: 15px;
        line-height: 19px;
        vertical-align: middle;
      }
    }

    .mm-card-price-wrap {
      p {
        color: $black-100;
        line-height: 14px;
        font-size: 14px;
        margin-bottom: 0;

      }

      h6 {
        color: $black-100;
      }

      &.mm-pay-wrap {
        margin-top: 18px;

        h6 {
          margin-bottom: 0;

        }

        h3 {
          color: $black-100;
          font-size: 20px;
          line-height: 22px;
          margin-bottom: 0;
        }
      }
    }

    .theme_btn {
      min-width: 140px;
    }
  }

  .mm-acct {
    position: relative;

    .mm-icon {
      position: relative;
      top: -9px;
      margin-left: 5px;
      text-align: center;
      color: #fff;
      font-size: 11px;
      line-height: 15px;
    }
  }

}

.yr-clr {
  color: $yellow-500;
}

.mm-icon {
  width: 18px;
  height: 18px;
  background-color: $input-border;
  border-radius: 50%;
  border: 1px solid $input-border;
  display: inline-block;
}

.mm-form-group2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  .input_modify {
    width: 80%;
  }
}

.sm-text {
  font-size: 11px;
  line-height: 14px;
  vertical-align: 2px;
}

.mm-form {
  .mm-form-link {
    color: $orange-200;
    font-size: 13px;
    line-height: 16px;
    cursor: pointer;
  }

  .mm-credit-wrapper {
    margin-top: 5px;

    .input-group-text {
      padding: 0;
      background-color: transparent;
      border: 0;
      border-radius: 0;
      height: 100%;
    }

    .input_modify {
      font-size: 14px;
      color: #161E54;
      line-height: 18px;
      font-weight: 600;
      border: 0;
      height: 24px;
      padding: 6px 12px;
    }
  }


}

.payment-card {
  .card-body {
    padding: 20px 16px;
  }

  .mm-form {
    .mm-form-link {

      font-size: 12px;
      line-height: 16px;

      img {
        margin-left: 3px;
        vertical-align: -3px;
      }
    }
  }
}

.mm-card-price-wrap {
  justify-content: space-between;
  text-align: left;

}

hr {
  border: 1px solid #ccc;
}

.subscription-form {

  .input_modify {
    font-size: 13px;
    line-height: 48px;
    width: 80%;

    &::-ms-input-placeholder {
      color: $brand-gray-600;

    }

    &::-webkit-input-placeholder {
      color: $brand-gray-600;
      opacity: 1;
    }

    &::placeholder {
      color: $brand-gray-600;


    }

  }

}

.card-summary {
  h5 {
    font-size: 15px;
  }
}

.mm-media-content {
  margin-left: 12px;
}

.mm-hr {
  margin-left: -16px;
  margin-right: -16px;

  &.mm-hr-alter {
    margin-left: 0;
    margin-right: 0;
    border-bottom-style: dashed;
  }
}

.mm-subscription-plan {
  .check-cont {
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    padding-left: 35px;

    .checkbox-check {
      top: -2px;
    }

    .checkbox-check {
      background-color: $white;
    }

  }

}

.plan-upgrade-section {
  .minmind-card-body {
    h4 {
      line-height: 20px;
    }


  }

  h5 {
    line-height: 26px;
  }

  .back-link-wrapper {
    line-height: 26px;
    font-size: 18px;
  }
}

.mm-credit-card {
  .mm-cc-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .cc-img {
      .mm-cc-head {
        color: $black-100;
        font-size: 13px;
        line-height: 28px;
        font-weight: 700;
        margin: 0;
        margin-left: 15px;
      }
    }

    .mm-next-img {

      img {
        width: 7px;
      }
    }
  }
}

.check-cont.check-cont-alt input:checked~.checkbox-check {
  background-color: #174e4d !important;
  // border-color: #174e4d;
}

.check-cont-alt {
  color: #717784;
  font-size: 13px;
  line-height: 30px;

  .checkbox-check {
    &::after {
      border: solid #fff;

      border-width: 0 3px 3px 0;
    }
  }
}

.site-section {
  .cc-card-plan {
    border-left: 0;
    border-right: 0;
    background-color: transparent;
  }
}
@import "../../../node_modules/bootstrap/scss/bootstrap.scss";
// @import "../node_modules/bootstrap-icons/font/bootstrap-icons.scss";
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.1/font/bootstrap-icons.css");
@import "functions";
@import "mixins";
@import "custom_variables";
@import "typography";
@import "utilities";
@import "button_styles";
@import "header";
@import "forms";
@import "./pages/splash/splash";
@import "./pages/sessions/sessions";
@import "./pages/lessions/lessions";
@import "./pages/login/login";
@import "./pages/plan/plan";
@import "./pages/dashboard/dashboard";
@import "custom";
.onboard-section {
  .mm-card {
    min-height: 135px;
  }
}

.min-mind-section {
  padding: 20px 5px;

  .minmind-article {
    h1 {
      color: #174e4d;
      font-weight: 700;
      font-size: 24px;
    }

    p {
      font-size: 16px;
      font-weight: normal;
      line-height: 24px;
      margin-top: 0px;
      margin-bottom: 5px;
      color: #455A64;
    }
  }
}



.mm-card {
  background-color: $brand-primary;
  border-radius: 15px;
  margin-bottom: 15px;

  h5 {
    font-size: 18px;
    color: $white;
    line-height: 24px;
    margin-bottom: 5px;
  }

  p {
    color: $orange-200;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0;

  }

  .card-body {
    position: relative;
    padding: 15px 37px 15px 20px;
  }


  .mm-icon-wrapper {
    position: absolute;

    top: 8px;
    right: 20px;

    img {
      width: 14px;

    }
  }

  &.mm-sm-card {
    min-height: 55px;
    border-radius: 10px;

    h5 {
      margin-bottom: 0;
    }

    .mm-icon-wrapper {

      top: calc(50% - 28.5px/2);

      img {
        width: 11px;
      }
    }
  }
}

.mm-tab-content {
  margin-top: 20px;
}

​.minmind-accordian {
  padding-top: 20px;

  .accordion-item {
    margin-bottom: 20px;
    background-color: #fff;
    border: none;

    .accordion-button {
      background-color: #174E4D;
      border-radius: 10px !important;
      color: #fff;

      &::after {
        background-image: url(../../../images/icons/icn-arrow-right.svg);
        background-size: 10px;
      }

      &:focus {
        box-shadow: none;
      }
    }
  }

}

.yin-yang-relax-section {
  .yin-yang-relax-article {
    a {
      color: #174E4D;
      font-size: 16px;
      margin-left: 10px;
      line-height: 19px;
      font-weight: 700;
    }

    img {
      margin-right: 15px;
    }
  }
}

.minmind-tabs-wrapper {
  .nav-tabs {
    .nav-link {
      padding: 8px 20px;
      border-radius: 20px;
      border: 0;
      border-radius: 50px;
      width: 100%;
      //    color: red;
      color: #174e4d;

      &.active {
        background-color: #174e4d;
        color: #e1d75d;

      }

      .minmind-tabs {
        //   border-top-right-radius: 0;
        //   border-bottom-right-radius: 0;
        padding: 10px 50px;
        border-right: 0;
      }
    }
  }

  .nav-tabs-wb {
    .nav-item {
      width: 33.33%;
    }
  }

  ul {
    border-radius: 25px;
    border: 1px solid #ccc;
    padding: 5px;

    .nav-item {
      width: 50%;
    }
  }

  .browse-tabs {
    border-radius: 0px;
    border-bottom: 1px solid #ccc;
    padding: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
  }
}

.minmind-browse-wrap {
  .nav-tabs {
    .nav-link {
      border: 0;
      border-radius: 0;
      width: auto;
      color: $brand-primary-100;
      background-color: transparent;
      border-bottom: 4px solid transparent;
      padding: 0 0 6px 0;
      margin-bottom: -2px;
      text-align: left;
      font-size: 14px;
      line-height: 24px;
      font-weight: $ft_Weight_M;
      display: inline;

      &.active {
        color: $brand-primary-100;
        font-weight: $ft_Weight_SM;
        border-bottom-color: $orange-200;
        background-color: transparent;
      }
    }
  }
}

.minmind-tabs-wrapper {
  margin-left: -25px;
  margin-right: -25px;


  .nav-tabs-wb {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-left: 25px;
    padding-right: 25px;

    .nav-item {
      width: auto;
      display: inline-block;

    }

  }

  .mm-tab-content {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.minmind-media-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  .mm-time-wrap {
    p {
      color: $brand-primary-200;
      font-size: 13px;
      line-height: 20px;
      font-weight: 500;
      margin-bottom: 0;

    }
  }
}

.minmind-media {

  h5 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    color: $brand-primary;
    margin-bottom: 0;
  }

  p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: $orange-100;
    margin-bottom: 0;
  }

  .mm-fig {
    background-color: $brand-primary;
    height: 50px;
    width: 50px;
    border-radius: 10px;
    margin-bottom: 0;
    text-align: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .mm-media-content {
    margin-left: 12px;
  }
}

.session-details-list {
  margin-bottom: 20px;
}

.mm-sscroller {
  @include respond(phone) {
    max-height: 60vh;
    overflow-y: auto;
  }
}

.script-section {
  .mm-sscroller {
    @include respond(phone) {
      max-height: 48vh;
    }
  }
}

// @media (device-height: 667px) and (device-width: 375px) and (-webkit-min-device-pixel-ratio: 2) {
//   .mm-sscroller {
//     max-height: 50vh;
//   }
// }
/**** Main header css here ****/
.main_header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  transition: all 0.1s ease-in-out;
  border-bottom: 1px solid transparent;
  background: transparent;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 1)), to(rgba(249, 251, 251, 1)));
  background: -o-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(249, 251, 251, 1) 100%);
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(249, 251, 251, 1) 100%);

  // -webkit-box-shadow: 4px 4px 30px 0px rgba(42, 67, 113, 0.15);
  // -moz-box-shadow: 4px 4px 30px 0px rgba(42, 67, 113, 0.15);
  // box-shadow: 4px 4px 30px 0px rgba(42, 67, 113, 0.15);
  @include respond(tab-port-min) {
    background-color: $white;
    -webkit-backdrop-filter: blur(20px) saturate(180%);
    backdrop-filter: blur(20px) saturate(180%);
    -webkit-animation: stickySlideDown 0.65s cubic-bezier(0.23, 1, 0.32, 1) both;
    -moz-animation: stickySlideDown 0.65s cubic-bezier(0.23, 1, 0.32, 1) both;
    animation: stickySlideDown 0.65s cubic-bezier(0.23, 1, 0.32, 1) both;
  }

  .theme_navigation {
    padding: 0 1.5rem;
    height: 75px;
    -webkit-transition: height 0.3s;
    -moz-transition: height 0.3s;
    transition: height 0.3s;
  }

  .logo {
    max-height: 100%;
  }

  ul.main_menu {
    align-items: center;
    height: 100%;
    font-size: 16px;
    font-weight: 500;
    list-style: none;
    position: relative;

    .nav-item {
      position: relative;
      padding: 0;
      float: left;
      position: relative;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      height: 100%;

      &::before,
      &::after {
        position: absolute;
        height: 5px;
        width: 0%;
        bottom: -3px;
        left: 0;
        background: $orange-100;
        content: "";
        display: block;
        border-top-left-radius: 5px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 5px;
        -webkit-border-top-left-radius: 5px;
        -webkit-border-top-right-radius: 0;
        -webkit-border-bottom-right-radius: 0;
        -webkit-border-bottom-left-radius: 5px;
        -moz-border-radius-topleft: 5px;
        -moz-border-radius-topright: 0;
        -moz-border-radius-bottomright: 0;
        -moz-border-radius-bottomleft: 5px;
        -webkit-transition: all 0.3s linear;
        -moz-transition: all 0.3s linear;
        -o-transition: all 0.3s linear;
        -ms-transition: all 0.3s linear;
        transition: all 0.3s linear;
      }

      &:before {
        border-top-left-radius: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 0;
        -webkit-border-top-left-radius: 0;
        -webkit-border-top-right-radius: 5px;
        -webkit-border-bottom-right-radius: 5px;
        -webkit-border-bottom-left-radius: 0;
        -moz-border-radius-topleft: 0;
        -moz-border-radius-topright: 5px;
        -moz-border-radius-bottomright: 5px;
        -moz-border-radius-bottomleft: 0;
        left: auto;
        right: 0;
      }

      &:hover,
      &.current_menu_item {
        &::before {
          left: 50%;
          width: 50%;
        }

        &::after {
          left: auto;
          right: 50%;
          width: 50%;
        }

        .nav-link {
          color: $orange-100;
        }
      }

      &.not-hover-link {

        &::before,
        &::after {
          content: none;
        }
      }

      .nav-link {
        font-size: 15px;
        display: inline-block;
        padding: 15px 0px 15px 0px;
        line-height: 35px;
        text-decoration: none;
        text-align: center;
        outline: none;
        color: $black;
        white-space: nowrap;
        -webkit-transition: all 0.2s ease-out 0s;
        -moz-transition: all 0.2s ease-out 0s;
        -o-transition: all 0.2s ease-out 0s;
        transition: all 0.2s ease-out 0s;

        &.active {
          color: $brand-primary;
          font-weight: bolder;
        }
      }

      &.menuItemHasChildren {
        a.nav-link {
          padding-right: 15px !important;

          @include respond(tab-port) {
            padding-left: 15px !important;
          }
        }
      }

      &.nav-item-search {
        display: none;

        @include respond(tab-port-min) {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
        }


        &.active {
          .minisearch {
            opacity: 1;
            visibility: visible;
            -webkit-transform: translateY(0);
            transform: translateY(0);
            -webkit-transition: opacity 0.5s, -webkit-transform 0.5s;
            transition: opacity 0.5s, transform 0.5s;
          }

          a {
            &::before {
              content: "\f00d";
              display: block;
              font-family: "FontAwesome";
              font-style: normal;
              font-size: 22px;
              font-weight: 400;
              margin-right: 5px;
              text-align: center;
              vertical-align: top;
              width: 100%;
            }
          }
        }

        a {
          &::before {
            content: "\f002";
            display: block;
            font-family: "FontAwesome";
            font-style: normal;
            font-size: 22px;
            font-weight: 400;
            margin-right: 5px;
            text-align: center;
            vertical-align: top;
            width: 100%;
          }
        }

        .minisearch {
          position: absolute;
          top: 100%;
          left: auto;
          right: 0;
          padding: 20px;
          width: 280px;
          opacity: 0;
          visibility: hidden;
          background-color: $white;
          -webkit-transform: translateY(20px);
          transform: translateY(20px);
          -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
          transition: opacity 0.3s, transform 0.3s;

          .sf-input {
            font-size: 16px;
            width: 100%;
            height: 40px;
            border-radius: 4px;
            padding: 0 10px;
            padding-right: 40px;
            outline: none;
            background-color: #f4f6f7;
            border-bottom: 1px solid #fff;
            border-left: 1px solid #fff;
            border-right: 1px solid #fff;
            border-top: 1px solid #fff;
          }

          .sf-submit-icon {
            position: absolute;
            right: 20px;
            width: 40px;
            height: 40px;
            line-height: 40px;
            text-align: center;
          }

          .sf-submit {
            position: absolute;
            right: 20px;
            width: 40px;
            height: 40px;
            margin: 0;
            background: transparent;
            border: 0;
            cursor: pointer;
            letter-spacing: 1.4px;
            color: #fff;
            text-transform: uppercase;
            font-style: normal;
            font-weight: 700;
            display: inline-block;
            text-align: center;
            white-space: nowrap;
            vertical-align: middle;
            line-height: 40px;
            padding: 0 17px;
            font-size: 14px;
            transition: all 0.3s;
            border-radius: 3px;
          }
        }
      }
    }


  }

  .theme-dropdown {
    .nav-link {
      &::after {
        position: absolute;
        top: 36px;
        right: 0;
        font-size: 18px;
        height: 7px;
        line-height: 6px;
        font-weight: 400;
        font-family: FontAwesome;
        content: "\f107";
        -webkit-transition: all 0.2s ease-in-out;
        -ms-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        border: 0;
      }
    }

    &:hover {
      .nav-link {
        &:after {
          -webkit-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          transform: rotate(180deg);
          color: $orange-100;
          ;
        }
      }
    }
  }

  .mm-profile-logo {
    width: 35px;
  }
}

.vsDropdownMmenuafter {
  position: absolute;
  left: 38px;
  top: 0;
  width: 10px;
  height: 10px;
  display: block;
  background: $white;
  transform: rotate(45deg) translate(-7px);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 10;
  box-sizing: border-box;
}

.header__logo {
  height: 32px;
}

/** toggle nav **/
.-open .navigation-toggler .icon-bar:nth-child(2) {
  transform: rotate(45deg);
  width: 24px;
}

.-open .navigation-toggler .icon-bar:nth-child(3) {
  opacity: 0;
  width: 0;
}

.-open .navigation-toggler .icon-bar:nth-child(4) {
  transform: rotate(-45deg);
  width: 24px;
}

.navigation-toggler {
  background: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  position: absolute;
  left: 21px;
  top: 1.3rem;
  right: auto;
  z-index: 1001;

  .icon-bar {
    width: 25px;
    display: block;
    height: 2px;
    margin-bottom: 6px;
    transform-origin: 0;
    background: $brand-primary;
    border-radius: 1px;
    -webkit-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;

    &:nth-child(3) {
      width: 20px
    }

    &:nth-child(4) {
      width: 15px
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
}

/** toggle nav **/

/**** Main header css here ****/
.theme_navigation {
  .main_menu_wrap {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  ul {
    li {
      ul {
        li {
          a {
            font-size: 16px;
            line-height: 30px;
            font-weight: 500;
            color: #1a1b1e;
            text-align: left;
            display: block;
            padding: 5px 0px 5px 0px;
            position: relative;
            text-decoration: none;
            outline: none;
            text-transform: none;
            -webkit-transition: all 0.2s linear;
            -moz-transition: all 0.2s linear;
            -o-transition: all 0.2s linear;
            -ms-transition: all 0.2s linear;
            transition: all 0.2s linear;

            @media screen and (max-width: 991.98px) {
              font-size: 14px;
              line-height: 24px;
              padding: 10px 16px;
            }

            &::before {
              position: absolute;
              content: "";
              top: 50%;
              left: -15px;
              width: 6px;
              height: 6px;
              border-radius: 50%;
              -webkit-border-radius: 50%;
              -moz-border-radius: 50%;
              background-color: $orange-100;
              -webkit-transform: translateY(-50%);
              -moz-transform: translateY(-50%);
              transform: translateY(-50%);
              -webkit-transition: all 0.2s linear;
              -moz-transition: all 0.2s linear;
              -o-transition: all 0.2s linear;
              -ms-transition: all 0.2s linear;
              transition: all 0.2s linear;
              visibility: hidden;
              opacity: 0;
            }

            &:hover,
            &:focus {
              color: $orange-100;
              padding-left: 15px;
              background-color: transparent;

              &::before {
                left: 0px;
                visibility: visible;
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }

  .navbar_nav_right {
    .vsDropdownMmenuafter {
      left: auto;
      right: 38px;
    }
  }
}

.userCircle {
  width: 36px;
  height: 36px;
  display: inline-block;
  overflow: hidden;
  background: rgba(240, 245, 250, 0.44);
  border-radius: 50%;
  margin-right: 10px;
  vertical-align: middle;

  img {
    width: 100%;
    min-height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.avatarTxt {
  font-size: 13px;
}

@media screen and (min-width: 992px) {
  .main_header {
    -webkit-box-shadow: 4px 4px 30px 0px rgba(42, 67, 113, 0.15);
    -moz-box-shadow: 4px 4px 30px 0px rgba(42, 67, 113, 0.15);
    box-shadow: 4px 4px 30px 0px rgba(42, 67, 113, 0.15);
  }

  .navigation-toggler {
    display: none;
  }

  .theme_navigation.navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
  }

  .main_header ul.main_menu>.nav-item:not(:last-child) {
    margin-right: 30px;
  }

  .main_header .theme_navigation {
    padding: 0 2rem;
    justify-content: space-between;
  }

  .theme_navigation .main_menu_wrap {
    width: auto;

  }

  .main_header .logo {
    margin-right: 50px;
    width: auto;
  }

  .main_header .logo img {
    height: 40px;
  }

  .main_header {
    .theme-dropdown {

      &:hover,
      &.show {
        .dropdown-menu {
          -webkit-transform: translateY(0);
          -ms-transform: translateY(0);
          transform: translateY(0);
          visibility: visible;
          opacity: 1;
        }
      }

      .dropdown-menu {
        min-width: 274px;
        display: block;
        white-space: nowrap;
        padding: 25px 0px;
        position: absolute;
        top: calc(100% + 10px);
        left: 0;
        z-index: 10;
        visibility: hidden;
        opacity: 0;
        -webkit-transform: translateY(-30px);
        -ms-transform: translateY(-30px);
        transform: translateY(-30px);
        transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        border-radius: 15px;
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        box-sizing: border-box;
        margin: 0px;
        border: 0;
        background-color: $white;
        -webkit-box-shadow: 8px 8px 30px 0px rgba(42, 67, 113, 0.15);
        -moz-box-shadow: 8px 8px 30px 0px rgba(42, 67, 113, 0.15);
        box-shadow: 8px 8px 30px 0px rgba(42, 67, 113, 0.15);

        &::before {
          content: "";
          position: absolute;
          height: 10px;
          width: 100%;
          top: -8px;
          left: 0;
        }

        li {
          display: block;
          position: relative;
          padding: 0px 40px;

          a {
            font-size: 14px;
            font-weight: 400;
            letter-spacing: normal;
            line-height: 1.875;
            text-transform: none;
          }
        }
      }
    }

    .navbar_nav_right {
      .theme-dropdown {
        .dropdown-menu {
          left: auto;
          right: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .main_header ul.main_menu>.nav-item:not(:last-child) {
    margin-right: 20px;
  }

  .main_header .logo {
    margin-right: 30px;

  }

  .main_header ul.main_menu .nav-item .nav-link {
    font-size: 13px;


  }

  .main_header ul.main_menu {
    font-size: 15px;
  }
}

@media screen and (max-width: 991.98px) {
  .main_header .logo {
    margin-right: 0;
  }

  .main_header .theme-dropdown .dropdown-menu {
    width: 89%;
    position: absolute;
    margin-left: 15px;
    border: 0;
    border-radius: 8px;
    margin-top: 2px;
    box-shadow: 8px 8px 30px 0px rgba(42, 67, 113, 0.15);
  }

  .theme_navigation .main_menu_wrap {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1030;
    max-width: 260px;
    height: 100vh;
    overflow: scroll;
    padding-top: 0;
    text-align: left;
    align-items: center;
    flex-direction: column;
    justify-content: start;
    background-color: $brand-primary;
    touch-action: manipulation;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transition: all 0.6s ease-in-out;
    -ms-transition: all 0.6s ease-in-out;
    transition: all 0.6s ease-in-out;
  }

  .theme_navigation.-open .main_menu_wrap {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }

  .main_header ul.main_menu {
    width: 100%;
    text-align: left;
    margin: 0;
    padding: 10px 0;
    margin-top: 0;
    height: 100%;
    overflow: auto;
    background: white;
    background: -webkit-gradient(linear, left top, left bottom, from(white), to(#c6dbdb)) !important;
    background: -o-linear-gradient(top, white 0%, #c6dbdb 100%) !important;
    background: linear-gradient(180deg, white 0%, #c6dbdb 100%) !important;
  }

  .main_header ul.main_menu li.nav-item {
    position: relative;
    list-style: none;
    margin: 0;
    display: block;
    width: 100%;
    height: auto;
    padding: 0 25px;
  }

  .main_header ul.main_menu li.current_menu_item>a.nav-link,
  .main_header ul.main_menu li:hover>a.nav-link {
    background: transparent;
    color: $orange-100  !important;
  }

  .main_header ul.main_menu li.nav-item a.nav-link {
    font-size: 15px;
    text-transform: unset;
    position: relative;
    text-align: left;
    padding: 12px 0;
    line-height: 26px;
    display: block;
    color: $brand-primary;
    font-weight: 500;
    border-bottom: 1px solid $primary-border;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    font-family: $font-sf-ui-display;
  }

  .main_header .theme_navigation {
    height: 50px;
  }

  .navbar-light .navbar-toggler {
    float: right;
    border-color: $white;
    background-color: $white;
  }

  #navbarNavDropdown {
    background-color: $white;
    float: left;
    text-align: center;
    width: 100%;
  }

  ul.main_menu.navbar_nav_right {
    margin-top: 0 !important;
  }

  .mainWrapper .contentWrapper {
    margin-top: calc(64px + 1px);
  }

  .vsDropdownMmenuafter {
    left: 25px;
  }

  .main_header ul.main_menu>.nav-item:not(:last-child) {
    margin-right: 0;
  }

  .main_header ul.main_menu .nav-item:before {
    display: none;
  }



  .main_header .theme-dropdown .nav-link::after {
    top: 21px;
    right: 15px;
  }

  .main_header ul.main_menu .nav-item::before,
  .main_header ul.main_menu .nav-item::after {
    display: none;
  }

  .main_header .theme-dropdown .nav-link::after {
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

  .main_header .theme-dropdown:hover .nav-link:after {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    color: $orange-100;
  }

  .vsDropdownMmenuafter {
    display: none;
  }
}

@media screen and (max-width: 330px) {
  .theme_navigation .main_menu_wrap {
    width: 250px;
  }
}

/** side bar notifications **/
.email-link {
  color: $orange-200;

  &:hover {
    color: $orange-100
  }
}

.mm-profile-details-wrapper {
  padding: 27px 25px 19px;
  font-family: $font-sf-ui-display;
  width: 100%;

  .mm-profile {
    width: 74px;
    height: 74px;
    border-radius: 50%;
    line-height: 74px;
    background-color: $brand-primary-100;
    display: inline-block;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  .mm-profile-link {
    color: $white;
    margin-top: auto;
    display: inline-block;
    margin-left: 8px;
    font-size: 14px;
    vertical-align: bottom;
  }

  .email-link {
    font-size: 14px;
  }
}

.mm-notification-wrapper {
  font-size: 13px;
  line-height: 14px;
  color: #fff;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  .mm_status_wrap {}

  .mm-switch {

    width: 40px;
    height: auto;

    .mm-slider {
      width: 34px;
      height: 18px;

      &:before {
        height: 16px;
        width: 16px;
        left: -8px;
        bottom: 1px;
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
        background-color: $gray-500;
      }
    }
  }

  input:checked+.mm-slider {
    background-color: $white;
  }

  input:checked+.mm-slider:before {
    -webkit-transform: translateX(9px);
    -ms-transform: translateX(9px);
    transform: translateX(9px);
    background-color: $brand-primary;
  }

}

.mm-profile-cont-wrapper {
  margin-top: 12px;

  h4 {
    margin-bottom: 2px;
  }
}

/** side bar notifications **/
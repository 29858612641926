.main-cont-wrapper {
  &.main-cont-hero-wrapper {
    padding-top: 0;
  }

  &.main-cont-gradient-wrapper {
    .hero-section {
      background-image: none;

      .form-link-wrapper {
        color: $brand-primary;
      }

      .mm-link {
        color: $orange-200;
      }
    }
  }

  &.main-cont-gradient-wrapper-v1 {
    .hero-section {
      background: rgb(255, 250, 248);
      background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(rgba(255, 250, 248, 1)),
        color-stop(50%, rgba(255, 255, 255, 1)),
        to(rgba(241, 244, 244, 1))
      );
      background: -o-linear-gradient(
        top,
        rgba(255, 250, 248, 1) 0%,
        rgba(255, 255, 255, 1) 50%,
        rgba(241, 244, 244, 1) 100%
      );
      background: linear-gradient(
        180deg,
        rgba(255, 250, 248, 1) 0%,
        rgba(255, 255, 255, 1) 50%,
        rgba(241, 244, 244, 1) 100%
      );
    }
  }

  &.main-cont-gradient-wrapper-v2 {
    .hero-section {
      background: rgb(255, 255, 255);
      background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(rgba(255, 255, 255, 1)),
        color-stop(50%, rgba(255, 228, 222, 1)),
        to(rgba(255, 255, 255, 1))
      );
      background: -o-linear-gradient(
        top,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 228, 222, 1) 50%,
        rgba(255, 255, 255, 1) 100%
      );
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 228, 222, 1) 50%,
        rgba(255, 255, 255, 1) 100%
      );
    }
  }
}

.rm-flex-between {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

/*** hero section ***/

.hero-section {
  // background-color: $gray-500;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;
  padding: 30px 0px;
  position: relative;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  // background-image: url("../../assets/images/background/hero1.svg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  .container {
    height: 100%;
  }

  .mm-article {
    position: relative;
    z-index: 2;
    padding-top: 76px;

    h1 {
      font-size: 52px;
      line-height: 63px;
      color: $black;
      margin-bottom: 5px;
    }

    h6 {
      font-size: 22px;
      line-height: 27px;
      color: $brand-primary;
      margin-bottom: 0;
    }

    h2 {
      font-size: 20px;
      line-height: 24px;
      color: $brand-primary;
    }

    h5 {
      color: $white;
    }
  }

  .mm-btn-wrapper {
    margin-top: 30px;
    margin-bottom: 30px;
    padding-bottom: 0;

    .theme_btn {
      width: 100%;
    }
  }

  .form-link-wrapper {
    text-align: center;
    font-size: 16px;
    color: $white;
  }

  .mm-link {
    color: $white;
  }
}

/** hero section with different version **/
.main-cont-hero-wrapper-v2 {
  .hero-section {
    background-image: url("../../../../assets/images/background/hero2.svg");
    background-position: bottom center;
    background-size: 100%;

    .mm-article {
      position: relative;
      z-index: 2;
      padding-top: 30vh;
    }

    .form-link-wrapper {
      color: $brand-primary;
    }

    .mm-link {
      color: $orange-100;
    }

    .container {
      @include respond(tab-port-min1) {
        max-width: 400px;
      }
    }
  }

  &.main-cont-hero-wrapper-v3 {
    .hero-section {
      // background-image: url("../../assets/images/background/hero3.svg");
    }
  }
}

.mm-btn-wrapper {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

/** hero section with different version **/
/*** Hero section ***/

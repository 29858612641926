.mm-form-group {
  margin-bottom: 20px;
}

.label_modify {
  margin-bottom: 8px;
  font-size: 13px;
  display: inline-block;
  max-width: 100%;
  color: $brand-primary;
  font-weight: 400;
  line-height: 15px;
}

.input_modify {
  font-weight: $ft_Weight_M;
  color: $brand-primary;
  width: 100%;
  height: 52px;
  background-color: $white;
  border-radius: 5px;
  padding: 10px 16px;
  font-size: 14px;
  line-height: 22px;
  display: block;
  border: 1px solid $input-border;
  background-clip: padding-box;
  appearance: none;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus,
  &:active {
    border-color: $brand-primary-hover;
    outline: 0;
    box-shadow: none;
  }

  &::-ms-input-placeholder {
    color: $brand-primary;
    opacity: 1;
  }

  &::-webkit-input-placeholder {
    color: $brand-primary;
    opacity: 1;
  }

  &::placeholder {
    color: $brand-primary;
    opacity: 1;
  }
}

textarea {
  height: auto !important;
  border-radius: 10px !important;
}

select.select_modify {
  background-image: image_url("icn_select_drop.svg");
  background-repeat: no-repeat;
  background-position: right 16px top 23px;
  background-size: 12px;
  -webkit-appearance: none;
}

.mm-fieldset {
  margin-top: 20px;
  margin-bottom: 50px;
}

.mm-form {
  .check-cont {
    padding-right: 20px;

    .checkbox-check {
      top: 0;
    }
  }

  &.mm-form-script {
    .mm-btn-wrapper {
      padding-top: 9vh;
    }
  }

  &.mm-form-subscription-plan {
    .mm-btn-wrapper {
      padding-top: 30px;
      padding-bottom: 0;
    }
  }
}

.wrap-login100 {
  width: 500px;
  height: 100%;
  background: $white;
  border-radius: 10px;
  overflow: hidden;
  padding: 45px;
  margin: 40px auto 25px;
  //border: 1px solid $gray_02;
  -webkit-box-shadow: 0px 0px 33px #16449a0d;
  box-shadow: 0px 0px 33px #16449a0d;
}

.login100-form {
  width: 100%;

  .theme_btn {
    min-width: 300px;
    border-radius: 25px;
    text-transform: uppercase;
  }

  .login_or.text-center {
    margin: 35px 0 20px 0;
  }

  .login100-form-title {
    display: block;
    font-size: 35px;
    color: $black;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 35px;
    font-weight: 600;
  }

  .form_fields {
    margin-bottom: 35px;
  }
}

select.cstSelectW160 {
  background-position: right 16px top 23px;
}

.form-link-wrapper {
  font-size: 15px;
  line-height: 24px;
  color: $brand-primary;
}

.mm-link {
  color: $orange-100;

  &:hover {
    color: $orange-100-hover;
  }
}

/*** Checkbox componets css here ***/
.check-cont {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 13px;
  line-height: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: $brand-primary;

  &:hover {
    input ~ .checkbox-check {
      background-color: transparent;
      border-color: $brand-primary;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkbox-check {
      background-color: transparent;
      border-color: $brand-primary;

      &::after {
        opacity: 1;
      }
    }
  }

  .checkbox-check {
    position: absolute;
    top: -2px;
    left: 0;
    height: 22px;
    width: 22px;
    background-color: $white;
    border: 1px solid $input-border;
    -webkit-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    border-radius: 3px;

    &::after {
      left: 7px;
      top: 2px;
      width: 7px;
      height: 12px;
      border: solid $brand-primary;
      content: "";
      position: absolute;
      opacity: 0;
      transition: all 0.4s ease-in-out;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

/*** Checkbox componets css here ***/
/*switch checkbox css start here */

.mm-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.mm-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.mm-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 40px;
  height: 27px;
  background-color: #d9d9d9;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.mm-slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: -6px;
  bottom: 5px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  -webkit-transform: translateX(9px);
  -ms-transform: translateX(9px);
  transform: translateX(9px);
}

input:checked + .mm-slider {
  background-color: #39d7a0;
}

input:focus + .mm-slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .mm-slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.mm-slider.mm-round {
  border-radius: 34px;
}

.mm-slider.mm-round:before {
  border-radius: 50%;
}

/*switch checkbox css end here */

// Radio Button starts
.check-select {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  font-size: 13px;
  color: #174e4d;
  font-weight: 600;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.check-select input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.select-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #fff;
  border: 1px solid #174e4d;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  border-radius: 50%;
}

.check-select input:checked ~ .select-checkmark {
  background-color: #174e4d;
}

.select-checkmark {
  &::after {
    content: "";
    position: absolute;
    display: none;
    top: 5px;
    left: 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: white;
  }
}

.check-select input:checked ~ .select-checkmark:after {
  display: block;
}

.select-checkmark {
  background-color: transparent;
}
// Radio Button ends

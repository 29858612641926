.dashboard-section {
  .mm-card {
    .card-body {
      position: relative;
      padding: 27px 20px 27px 20px;
    }

    &.mm-md-card {
      .card-body {
        position: relative;
        padding: 27px 15px;
      }

      .mm-card-fig {
        margin-bottom: 20px;
      }

      p {
        line-height: 21px;
        margin-bottom: 10px;
      }
    }

    &.mm-sm-card {
      p {
        font-size: 13px;
      }

      .card-body {
        padding: 15px 15px;
      }

      .theme_btn_sm {
        font-size: 11px;
        padding: 9.5px 11px;
        line-height: 14px;
        min-width: 110px;
      }
    }
  }

  .mm-card-list-wrapper {
    margin-bottom: 15px;
  }

  .mm-media-content {
    margin-left: 15px;
  }

  .mm-card-gride {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 20px 1fr;
    grid-template-columns: 1fr 1fr;
    -webkit-column-gap: 20px;
    -moz-column-gap: 20px;
    column-gap: 20px;
  }
}

.mm-circle {
  width: 56px;
  height: 56px;
  display: inline-block;
  vertical-align: middle;
  -webkit-box-shadow: 0px 3px 6px $orange-300;
  box-shadow: 0px 3px 6px $orange-300;
  background-color: $brand-primary;
  border-radius: 50%;
  line-height: 56px;
  text-align: center;
  border: 1px solid $orange-300;
  font-size: 18px;
  font-weight: 600;
  color: $white;
}
.loader-spinner {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
svg {
  width: 90%;
  fill: none;
}
.load {
  transform-origin: 50% 50%;
  stroke-dasharray: 0.7 0.3;
  stroke-linecap: round;
  stroke-width: 2px;
  stroke: #f2795e;
}
.load.one {
  animation: spinners 1.5s infinite ease-in;
  animation-direction: reverse;
}
.load.two {
  fill: #f2795e;
}
.load.three {
  animation: spinners 1.5s infinite;
}
@keyframes spinners {
  100% {
    transform: rotate(360deg);
  }
}

@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  color: $brand-secondary;
  font-family: $font-base;
  font-weight: 400;
  font-size: 14px;
  font-style: normal;
  line-height: 150%;
  background-color: $white;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-break: break-word;
  overscroll-behavior-y: contain;
}

.ft-weight-100 {
  font-weight: $ft_Weight_100 !important;
}

.ft-weight-200 {
  font-weight: $ft_Weight_200 !important;
}

.ft-weight-300 {
  font-weight: $ft_Weight_300 !important;
}

.ft-weight-400 {
  font-weight: $regular !important;
}

.ft-weight-500 {
  font-weight: $ft_Weight_M !important;
}

.ft-weight-600 {
  font-weight: $ft_Weight_SM !important;
}

.ft-weight-700 {
  font-weight: $bold !important;
}

$-h1: 35px;
$-h2: 30px;
$-h3: 24px;
$-h4: 20px;
$-h5: 18px;
$-h6: 16px;
$para-font: 15px;
$border-rad5: 5px;

h1 {
  font-size: $-h1;
}

h2 {
  font-size: $-h2;
}

h3 {
  font-size: $-h3;
  line-height: 150%;
}

h4 {
  font-size: $-h4;
}

h5 {
  font-size: $-h5;
}

h6 {
  font-size: $-h6;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}

.font13 {
  font-size: 13px;
}

.display-4 {
  font-size: 30px;
}

/* SF UI Display */
@font-face {
  font-family: "SF UI Display";
  src: url(font-path("sf-ui-display/SFUIDisplay-Heavy.woff2")) format("woff2"),
    url(font-path("sf-ui-display/SFUIDisplay-Heavy.woff")) format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF UI Display";
  src: url(font-path("sf-ui-display/SFUIDisplay-Black.woff2")) format("woff2"),
    url(font-path("sf-ui-display/SFUIDisplay-Black.woff")) format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF UI Display";
  src: url(font-path("sf-ui-display/SFUIDisplay-Bold.woff2")) format("woff2"),
    url(font-path("sf-ui-display/SFUIDisplay-Bold.woff")) format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF UI Display";
  src: url(font-path("sf-ui-display/SFUIDisplay-Semibold.woff2"))
      format("woff2"),
    url(font-path("sf-ui-display/SFUIDisplay-Semibold.woff")) format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF UI Display";
  src: url(font-path("sf-ui-display/SFUIDisplay-Medium.woff2")) format("woff2"),
    url(font-path("sf-ui-display/SFUIDisplay-Medium.woff")) format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF UI Display";
  src: url(font-path("sf-ui-display/SFUIDisplay-Regular.woff2")) format("woff2"),
    url(font-path("sf-ui-display/SFUIDisplay-Regular.woff")) format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF UI Display";
  src: url(font-path("sf-ui-display/SFUIDisplay-Light.woff2")) format("woff2"),
    url(font-path("sf-ui-display/SFUIDisplay-Light.woff")) format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF UI Display";
  src: url(font-path("sf-ui-display/SFUIDisplay-Thin.woff2")) format("woff2"),
    url(font-path("sf-ui-display/SFUIDisplay-Thin.woff")) format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

/* Montserrat */
@font-face {
  font-family: "Montserrat";
  src: url(font-path("montserrat/Montserrat-Black.woff2")) format("woff2"),
    url(font-path("montserrat/Montserrat-Black.woff")) format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url(font-path("montserrat/Montserrat-Bold.woff2")) format("woff2"),
    url(font-path("montserrat/Montserrat-Bold.woff")) format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url(font-path("montserrat/Montserrat-SemiBold.woff2")) format("woff2"),
    url(font-path("montserrat/Montserrat-SemiBold.woff")) format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url(font-path("montserrat/Montserrat-Medium.woff2")) format("woff2"),
    url(font-path("montserrat/Montserrat-Medium.woff")) format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url(font-path("montserrat/Montserrat-Regular.woff2")) format("woff2"),
    url(font-path("montserrat/Montserrat-Regular.woff")) format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url(font-path("montserrat/Montserrat-Light.woff2")) format("woff2"),
    url(font-path("montserrat/Montserrat-Light.woff")) format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url(font-path("montserrat/Montserrat-Thin.woff2"))
      format("woff2"),
    url(font-path("montserrat/Montserrat-Thin.woff"))
      format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

/* Josefin Sans */
// @font-face {
//   font-family: "Josefin Sans";
//   src: url(font-path("josefin-sans/JosefinSans-Black.woff2")) format("woff2"),
//     url(font-path("josefin-sans/JosefinSans-Black.woff")) format("woff");
//   font-weight: 900;
//   font-style: normal;
//   font-display: swap;
// }

@font-face {
  font-family: "Josefin Sans";
  src: url(font-path("josefin-sans/JosefinSans-Bold.woff2")) format("woff2"),
    url(font-path("josefin-sans/JosefinSans-Bold.woff")) format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Josefin Sans";
  src: url(font-path("josefin-sans/JosefinSans-SemiBold.woff2")) format("woff2"),
    url(font-path("josefin-sans/JosefinSans-SemiBold.woff")) format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Josefin Sans";
  src: url(font-path("josefin-sans/JosefinSans-Medium.woff2")) format("woff2"),
    url(font-path("josefin-sans/JosefinSans-Medium.woff")) format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Josefin Sans";
  src: url(font-path("josefin-sans/JosefinSans-Regular.woff2")) format("woff2"),
    url(font-path("josefin-sans/JosefinSans-Regular.woff")) format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Josefin Sans";
  src: url(font-path("josefin-sans/JosefinSans-Light.woff2")) format("woff2"),
    url(font-path("josefin-sans/JosefinSans-Light.woff")) format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Josefin Sans";
  src: url(font-path("josefin-sans/JosefinSans-Thin.woff2")) format("woff2"),
    url(font-path("josefin-sans/JosefinSans-Thin.woff")) format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

/* Poppins fonts */

@font-face {
  font-family: "Poppins";
  src: url(font-path("poppins/Poppins-Bold.woff2")) format("woff2"),
    url(font-path("poppins/Poppins-Bold.woff")) format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url(font-path("poppins/Poppins-Black.woff2")) format("woff2"),
    url(font-path("poppins/Poppins-Black.woff")) format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url(font-path("poppins/Poppins-Thin.woff2")) format("woff2"),
    url(font-path("poppins/Poppins-Thin.woff")) format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url(font-path("poppins/Poppins-Light.woff2")) format("woff2"),
    url(font-path("poppins/Poppins-Light.woff")) format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url(font-path("poppins/Poppins-ExtraLight.woff2")) format("woff2"),
    url(font-path("poppins/Poppins-ExtraLight.woff")) format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url(font-path("poppins/Poppins-Regular.woff2")) format("woff2"),
    url(font-path("poppins/Poppins-Regular.woff")) format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url(font-path("poppins/Poppins-SemiBold.woff2")) format("woff2"),
    url(font-path("poppins/Poppins-SemiBold.woff")) format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url(font-path("poppins/Poppins-Medium.woff2")) format("woff2"),
    url(font-path("poppins/Poppins-Medium.woff")) format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

/* Acumin Pro */

@font-face {
  font-family: "Acumin Pro";
  src: url(font-path("acumin-pro/AcuminPro-Bold.woff2")) format("woff2"),
    url(font-path("acumin-pro/AcuminPro-Bold.woff")) format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Acumin Pro";
  src: url(font-path("acumin-pro/AcuminPro-Regular.woff2")) format("woff2"),
    url(font-path("acumin-pro/AcuminPro-Regular.woff")) format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* Mulish */

@font-face {
  font-family: "Mulish";
  src: url(font-path("mulish/Mulish-Black.woff2")) format("woff2"),
    url(font-path("mulish/Mulish-Black.woff")) format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mulish";
  src: url(font-path("mulish/Mulish-ExtraLight.woff2")) format("woff2"),
    url(font-path("mulish/Mulish-ExtraLight.woff")) format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mulish";
  src: url(font-path("mulish/Mulish-Regular.woff2")) format("woff2"),
    url(font-path("mulish/Mulish-Regular.woff")) format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mulish";
  src: url(font-path("mulish/Mulish-Light.woff2")) format("woff2"),
    url(font-path("mulish/Mulish-Light.woff")) format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mulish";
  src: url(font-path("mulish/Mulish-Medium.woff2")) format("woff2"),
    url(font-path("mulish/Mulish-Medium.woff")) format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mulish";
  src: url(font-path("mulish/Mulish-SemiBold.woff2")) format("woff2"),
    url(font-path("mulish/Mulish-SemiBold.woff")) format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
